import { message } from "antd";
import axios from "axios";
import { error } from "jquery";

const service = axios.create({
    timeout: 1000 * 1000
});
// 新阿里云
// export default function ajax(url, data={}, type='GET'){
//     if(type === 'GET') return service.get("/managment"+url, {params: data})
//     else if(type === 'POST') return service.post("/managment"+url, data)
//     else if(type === 'PUT') return service.put("/managment"+url, data)
//     else if(type === 'DELETE') return service.delete("/managment"+url, data)
// }

// 目前通用，gateway
export default function ajax(url, data={}, type='GET'){
    if(type === 'GET') return service.get("/gateway"+url, {params: data}).catch(e=>{console.log('11111')});
    else if(type === 'POST') return service.post("/gateway"+url, data).catch((e,b)=>{console.log('2222222'+e)});
    else if(type === 'PUT') return service.put("/gateway"+url, data).catch(e=>{console.log('3333333')});
    else if(type === 'DELETE') return service.delete("/gateway"+url, data).catch(e=>{console.log('4444444')});
}

// export default function ajax(url, data={}, type='GET'){
//     if(type === 'GET') return service.get("/test"+url, {params: data})
//     else if(type === 'POST') return service.post("/test"+url, data)
//     else if(type === 'PUT') return service.put("/test"+url, data)
//     else if(type === 'DELETE') return service.delete("/test"+url, data)
// }

// 本地访问
// export default function ajax(url, data={}, type='GET'){
//     if(type === 'GET') return service.get(url, {params: data})
//     else if(type === 'POST') return service.post(url, data)
//     else if(type === 'PUT') return service.put(url, data)
//     else if(type === 'DELETE') return service.delete(url, data)
// }
