import React, { Component } from 'react'
import {Button, Layout, Radio} from "antd";
import TableComponents from "../../../components/common/tables/TableComponentsNew";
import {queryPushConfigs} from "../../../services/pushData";
import {TYPE_NAME} from "../../../components/Const";
import {queryReplaceMeterLedger, queryUpdateLedger} from "../../../services/report";
import FormsTwo from "../../../components/common/forms/FormsTwo";
import moment from "moment";
// 表具修改台账 -改 表具修改日志
export default class ModifyLedger extends Component {
    state={
        topFormItemData:{},
        topFormItem: {
            modal: true,
            number: 4,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {variableName: 'sheetSteelGrade', str: '表刚号', rules: TYPE_NAME.NAME,},
                {variableName: 'operator', str: '修改人', rules: TYPE_NAME.NAME,},
                {variableName: 'updateStarTime', str: '修改时间', rules: TYPE_NAME.RANGE_PICKER,},
                // {variableName: 'updateEndTime', str: '修改后时间', rules: TYPE_NAME.DATE_TIME_FORMAT,},
                {
                    variableName: 'updateContent', str: '修改类型', rules: TYPE_NAME.SELECT,
                    data:[
                        {key:'表钢号',value:'表钢号'},
                        {key:'安装位置',value:'安装位置'},
                        {key:'表具通讯号',value:'表具通讯号'},
                        {key:'区域',value:'区域'}
                    ]
                },
            ]
        },
        // 表格参数
        tableParamenter: {
            manageName: "表具修改台账",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                id: '台账Id',
                updateTime: '修改时间',
                operator: '修改人',
                beforeData: '修改前数据',
                afterData: '修改后数据',
                sheetSteelGrade: '表钢号',
                updateContent:'修改类型'
                // edit:'操作'
            },
            params:{
                type:'meter',
                size:10,
                page:1,
            }
        },
    };

    setTableValue = (value,text,rowData) => {

    };
    // 查询
    getConditionsValue = async() =>{
        let data = await this.forms.onFinishNotReset();
        console.log(data);
        let sTime=data?.updateStarTime ?moment(data?.updateStarTime[0]._d) : null;
        let eTime=data?.updateStarTime ?moment(data?.updateStarTime[1]._d) : null;
        let params = this.state.tableParamenter.params;
        Object.assign(params,data);
        params.updateStarTime=sTime;
        params.updateEndTime=eTime;
        let {tableParamenter} = this.state;
        tableParamenter.params = params;
        this.setState({tableParamenter});
        await this.table.refreshTable(params);
    };
    // 清空
    getEmptyValue = async () =>{
        await this.setState({topFormItemData:{}});
        await this.forms.onModalClose();
    };
    render(){
        return (
            <Layout className={'item_body_number_back tables'}>
                <div>
                    <div className={'topSearchBox'}>
                        <h3>表具修改日志</h3>
                        <div style={{width:'100%',height:'40px',position:'relative',backgroundColor:'#fff',paddingTop:'5px'}}>
                            <div style={{width:'85%'}}>
                                <FormsTwo
                                    ref={e => this.forms = e}
                                    formParamenter={this.state.topFormItem}
                                    data={this.state.topFormItemData}
                                />
                            </div>
                            <Button style={{position:'absolute', right:'150px',top:'5px'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
                            <Button style={{position:'absolute', right:'60px',top:'5px'}} onClick={() => this.getEmptyValue()}>清空</Button>
                        </div>
                    </div>

                    <div style={{width:'100%',height:'400px',padding:'0 0 0 0',background:'#fff'}}>
                        <TableComponents
                            paramenter={this.state.tableParamenter}
                            setData={queryUpdateLedger}
                            // setTableValue={this.setTableValue}
                            ref={e => this.table = e}
                        >
                            {/*<Radio.Group defaultValue="addClient" buttonStyle="solid">*/}
                            {/*    <Radio.Button value="addClient" onClick={()=>{this.modalData(true,{},'新增配置')}}>新增配置</Radio.Button>*/}
                            {/*</Radio.Group>*/}
                        </TableComponents>
                    </div>


                </div>
            </Layout>
        );
    }
}
