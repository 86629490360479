import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import {Layout, Modal, Radio, message, Tag, Popconfirm} from 'antd'
import {TYPE_NAME} from '../../../components/Const'
import {funTraceInfo} from '../../../services/common'
import TableComponents from '../../../components/common/tables/TableComponents'
import {

	getPageMpAddress,
	getLocationById,
	newGetPageCustomerName, cancelCustomerNew
} from '../../../services/customer'

// 客户信息管理
export default class Business extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "客户关系管理系统",
			// 是否绑定左侧区域
			bindArea: true,
			// 表格行是否可选
            rowSelection: true,
			// 获取表格参数数据
			localStorageName: 'ClientBusinessNew',
			//下拉框长度
			selectWidth: '7.5vw',
			// 表头：变量和变量解释
			fileName: '客户关系管理',
			// exportUrl: "/web/customerExportCtrl/exportCustomer",
			exportUrl: "/web/customerExportCtrl/exportCustomer",
			header: {
				id: '客户编号',
				businessCooperationNum: '业务合作号',
				name: '客户名称',
				type: '客户类型',
				nature: '客户性质',
				principal: '联系人',
				principalPhone: '联系人电话',
				detailAddress: '详细地址',
				edit: '操作',
			},
			// 条件选择器参数
			conditionsParamenter: {
				// 条件
				parameter: [
					{
						type: TYPE_NAME.NAME,
						variableName: 'customerName',
						str: '客户名称',
						initData: JSON.parse(localStorage.getItem('customer'))?.name
					},
					{
						type: TYPE_NAME.NAME,
						variableName: 'businessCooperationNum',
						str: '业务合作号',
						initData: JSON.parse(localStorage.getItem('customer'))?.businessCooperationNum
					},
				]
			},
		},
	};
    componentWillMount() {

	}

	// 注销客户
	cancelCustomer=(id)=>{
    	if(!id){return message.info('请选择客户');}
    	var param = {
			"page": 1,
			"size": 10,
			"address": "",
			"customerId": id
		};
    	// 验证计量点
		getPageMpAddress(param).then(
			response=>{
				// return message.info('该客户存在计量点或安装点，无法进行注销');
				console.log(response);
				if(response?.data?.data){
					console.log(response);
					return message.info('该客户存在计量点，无法进行注销');
				}else{
					var locatioParam = {customerId:id, page:1, size:10,};
					// 验证安装点
					getLocationById(locatioParam).then(
						response1=>{
							console.log(response1);
							if(response1?.data?.data){
								console.log(response1);
								return message.info('该客户存在安装点，无法进行注销');
							}else{
								cancelCustomerNew({customerId:id}).then(
									response2=>{
										if(response2.data.data){
											this.table.refreshTable();
											return message.info('注销成功');
										}else{
											return message.info('注销失败');
										}
									})
							}
						});
				}

			}
		);
	};

	setTableValue = (value,text,rowData) => {
		switch (value) {
			case 'id':
				return (
					<NavLink to={{pathname: "/customer/businessMaintain",state: {
							id: rowData.id,
							type: rowData.type*1 === 1 ? '居民用户' : rowData.type*1 === 2 ? '工商用户' : '',
							nature: rowData.nature,
							address: rowData.detailAddress,
							name: rowData.name,
							principalPhone: rowData.principalPhone
						}}}>
						<div className={'table_overflow'} title={text}>
							<u>{text}</u>
						</div>
					</NavLink>
				);
			case 'type':
				text = text*1;
				return text*1 === 1 ? <Tag color={'geekblue'}>居民用户</Tag> : text*1 === 2 ? <Tag color={'green'}>工商用户</Tag> : '';
			case 'state':
				text = text*1;
				return text === 1 ? '正式' : text === 2 ? '非正式' : '';
			case 'edit':
				var formsTwo_data={
						customerId:     rowData?.id,			// 用户id
						address:        rowData?.detailAddress,	// 地址
						customerName:   rowData?.name,   		// 客户名称
						createDate:'-',     // 创建时间
						mpName:         rowData?.principal,		// 联系人
						principalPhone: rowData?.principalPhone	// 电话
				};
				return (
					<div>
						<div style={{float: 'left'}}>
							<NavLink to={{
								pathname: "/customer/InstallationInfo",
								state:{
									rowData:formsTwo_data
								}
							}}
							>
								<div title={'安装点'}>
									<a>安装点</a>
								</div>
							</NavLink>
						</div>
						<div style={{paddingLeft:'10px',float: 'left'}}>
							<Popconfirm title="是否注销客户?"
										onConfirm={()=>{this.cancelCustomer(rowData?.id)}}
								// onCancel={cancel}
										okText="是" cancelText="否">
								<a>注销客户</a>
							</Popconfirm>
						</div>
					</div>
				);
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};
	updateCustomer = () => {
		let rowData = this.table.getSelectChange();
		if(rowData.length < 1) return message.error('未选择数据');
		rowData[0].regionManagementId=rowData[0]?.regionManagementId?String(rowData[0].regionManagementId):null;
		this.props.history.push({
			pathname: '/customer/openAccount',
			state: {
				rowData:rowData[0],
			}
		})
	};
    render() {

        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					// setData={getPageCustomerName}
					setData={newGetPageCustomerName}
					setTableValue={this.setTableValue}
					ref={e => this.table = e}
				>
					<Radio.Group defaultValue="addClient" buttonStyle="solid">
						<NavLink
							to={{
								pathname:"/customer/openAccount",
								state:{

								}
							}}

						>
							<Radio.Button value="addClient">新增客户</Radio.Button>
						</NavLink>
						<Radio.Button value="updateClient" onClick={this.updateCustomer}>修改客户</Radio.Button>
					</Radio.Group>
				</TableComponents>
				<Modal centered title="客户关系图" visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
					<p>客户关系图</p>
				</Modal>
            </Layout>
        )
    }
}
