import React, { Component } from 'react'
import { Layout, Button, message, Divider, Col, Row, Select,TreeSelect, Modal, Form } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import {saveMeter, getAllDevClass, selectDevInfoAttr, listDevModelInfo, listDeviceModelByDeviceClassId} from '../../../services/meter'

import {TYPE_NAME} from '../../../components/Const'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import {funTraceInfo} from '../../../services/common'
const { Option } = Select;
const FormItem = Form.Item;

export default class MenterOperating extends Component {

    state = {
		manufacture:null,// 供应商
    	classId:null,
        // 表单参数
		formParamenter: {
			modal: true,
			number: 3,
            labelCol: 6,
			layout: "horizontal",
			parameter: [
				{
					disabled:false,
					variableName: 'type',
					str: '表具类型',
					rules: TYPE_NAME.REQ_SELECT,
					data: [
						{key: '1', value: '备用表'},
						{key: '2', value: '用户表'}
					]
				},
				{
					disabled:false,
					variableName: 'communication',
					str: '表具通讯号',
					rules: TYPE_NAME.INPUT
				}
			]
		},
		modelForm: {
			modal: true,
			number: 2,
            labelCol: 6,
			layout: "horizontal",
			parameter: []
		},
		deviceForm: {
			modal: true,
			number: 3,
            labelCol: 6,
			layout: "horizontal",
			parameter: []
		},
		deviceFormItem: {
			modal: true,
			number: 3,
            labelCol: 6,
			layout: "horizontal",
			parameter: [
				// {
				// 	variableName: 'devName',
				// 	str: '设备名称',
				// 	rules: TYPE_NAME.NOT_BLANK
				// },
				{
					variableName: 'location',
					str: '安装位置',
					rules: TYPE_NAME.NOT_BLANK
				},
				{
					variableName: 'searchName',
					str: '检索名称',
					rules: TYPE_NAME.NOT_BLANK
				},
				{
					variableName: 'manufactureTime',
					str: '出厂日期',
					rules: TYPE_NAME.DATE_PICKER
				}
			]
		},
		// 型号信息
		modelData: [],
		// 类型信息
		typeData: [],
		// 设备初始值
		deviceItem: {},
		// 表具信息
		meterValue: {},
		// 设备信息
		deviceType: {
			classId:null,
			devModelName: null,
			devModelId: null, // 型号id
			model: null, // 型号名称
			attr: [] // 数据项
		},
		item: [],

		// 数据项默认数据
		deviceFormItemData: {}
    }

	async componentDidMount(){

		let devId = this.props.location.state?.devId;
		if(devId) this.getDevInfo(devId);// 根据设备id查询设备信息
		this.getData()	// 获取型号数据
	}

	// 根据设备id查询设备信息
	getDevInfo = devId => {
		selectDevInfoAttr({devId: devId}).then(
			response => {

				let {data} = response.data;
				this.openType1(data?.classId);
				let arr = {}, parameter = [];

				data?.attr?.forEach(value => {
					console.log(value,devId);
					arr[value.name] = value.value;
					if(value.name==='进气方向'){
						parameter.push({
							variableName: value.name,
							str: value.name,
							rules: TYPE_NAME.SELECT,
							data:[
								{
									key: '左进',
									value: '左进'
								},
								{
									key: '右进',
									value: '右进'
								}
							]
						})
					}else{
						parameter.push({
							variableName: value.name,
							str: value.name,
							rules: TYPE_NAME.INPUT,
							disabled: devId && value?.id === '1144' ? true : false
						})
					}

				});
				let {deviceType, deviceForm} = this.state;
				deviceForm.parameter = parameter;
				deviceType.devModelId = data?.devModelId;
				deviceType.model = data?.devModelName;



				this.setState({
					classId:data?.classId?data?.classId.toString():null,
					deviceItem: data,
					deviceType,
					deviceFormItemData: arr,
					deviceForm,
					item: data?.attr,
					devType: data?.className,
					devModel: data?.devModelName
				})
				setTimeout(() => this.deviceFromItem?.onModalClose(), 0)
			}
		)
	}

	// 提交表单
    onSave = async () => {
		let {meterValue, deviceType, deviceItem,deviceForm} = this.state;
		meterValue = await this.meterFrom.onFinishNotReset();
		deviceType = await this.deviceFrom.onFinishNotReset();
		deviceItem = await this.deviceFromItem.onFinishNotReset();
		console.log(deviceForm.parameter);
		console.log(1);
		if(deviceForm.parameter===undefined){
			return message.info('当前所选设备不可使用')
		}
		console.log(2);
		if(!meterValue || !deviceType || !deviceItem) return null;
		console.log(localStorage.getItem('areaName'));
		if(localStorage.getItem('areaName')==='undefined'){
			return message.info('请选择区域');
		}
		console.log(3);
		console.log(meterValue);
		// if(meterValue.type && meterValue.communication){
		if(meterValue.type){
			console.log(4);
			Modal.confirm({
				title: `是否绑定到${localStorage.getItem('areaName')}?`,
				onOk: () => {
					deviceType = this.getDeviceValue(deviceType);
					let value = Object.assign(meterValue, deviceType, deviceItem);
					let modelId = value.devModelId;
					value.id = this.props.location.state?.id;
					value.devId = this.props.location.state?.devId;
					value.areaName = localStorage.getItem('areaName');
					value.devModelId = this.props.location.state?.devModelId ?? modelId;
					value.meterAreaId = JSON.parse(localStorage.getItem('menus'))?.[0];
					console.log(value.devModelId);
					if(value.devModelId==null) {
						message.error('未选择型号');
						return true;
					}
					if(value?.attr?.length < 1) {
						message.error('该表没有数据项信息');
						return true;
					}
					let arr = value.attr?.filter(data => data.id === '3001' || data.id === '1144');
					if(arr.length < 1){
						message.error('该表没有表钢号或者物联网手机号');
						return true
					}
					value.classId=this.state.classId;


					value.manufacture=this.state.manufacture;
					value.operator= JSON.parse(sessionStorage.getItem("user")).username;
					console.log(value);
					saveMeter(value).then(
						response => {
							console.log(value);
							let {responseInfo = {}, data = false} = response.data;
							let msg = value.id ? '修改' : '新增';
							if(data) {
								message.success(msg+'表具成功');
								this.meterFrom.onModalClose();
								this.deviceFrom.onModalClose();
								this.deviceFromItem.onModalClose();
								// funTraceInfo("表具信息管理", "新增表具(修改)", null,value);
								this.props.history.replace('/meter/meterManagement')
							}else {
								if(responseInfo?.code === 200) message.error(responseInfo?.description);
								else message.error(msg+'表具失败')
							}
						}
					)
				}
			})
		}
    }

	// 取消表单
    clear = () => {
		if(this.props.location.state?.id){
			message.warn("修改时不允许清空")
		}else{
			let {deviceForm} = this.state
			deviceForm.parameter = []
			this.setState({
				devType: null,
				devModel: null,
				deviceForm
			})
			this.meterFrom.onModalClose()
			this.deviceFrom.onModalClose()
			this.deviceFromItem.onModalClose()
		}
    }

	// 表具型号发生改变时 devModel
	openModal = (e,option) => {
    	var manufacture = this.state.manufacture;
		manufacture=null;
		this.state.modelData.map(one=>{
			if(one?.devModelId===e){
				manufacture=one.manufacture;
			}
		});
		let {deviceType} = this.state;
		let devModel = this.state.devModel;
		deviceType.devModelId = e;
		deviceType.devModelName = option[0];
		devModel = option[0];
		this.setState({deviceType,devModel,manufacture});
		listDevModelInfo([e]).then(
			response => {
				let {data} = response.data ?? {};
				let items = data?.attr?.filter(value => value.common === 2);
				let {item, deviceForm} = this.state;
				console.log('--------------items---------------');
				console.log(items);
				if(items===undefined){
					message.info("当前所选设备不可用")
				}
				console.log('--------------items---------------');
				item = items?.map(
					value => {
						if(value?.id === '1501'){
							return {
								variableName: value.name,
								str: value.name,
								rules: TYPE_NAME.REQ_SELECT,
								data: [
									{
										key: '左进',
										value: '左进'
									},
									{
										key: '右进',
										value: '右进'
									}
								]
							}
						}
						return {
							variableName: value.name,
							str: value.name,
							rules: TYPE_NAME.NOT_BLANK
						}
					}
				);
				deviceForm.parameter = item;
				this.setState({deviceForm, item: items})
			}
		)
    };

	// 设备item信息
	getDeviceValue = value => {
		let {item,deviceType} = this.state
		let meter = []
		meter = item?.map(v => {
			return {
				id: v.id,
				name: v.name,
				value: value[v.name],
			}
		})
		deviceType.attr = meter
		return deviceType
	}

	// 获取型号数据
	getData = () => {
		getAllDevClass().then(
			response => {
				console.log('获取型号数据');
				console.log(response.data.data);
				this.setState({typeData: response.data.data})
			}
		)
	};

	openType = (e,_) => {
    	this.setState({classId:e});
		listDeviceModelByDeviceClassId({deviceClassId: e}).then(
			response => {
				if(response?.data?.data){

					let listData = [];
					listData = response?.data?.data;

					listData.sort(function(a, b) {
						let nameA = a.devModelName.toUpperCase();
						let nameB = b.devModelName.toUpperCase();

						if (nameA < nameB) {
							return -1;
						}
						if (nameA > nameB) {
							return 1;
						}
					});
					this.setState({modelData: listData, devModel: null})
				}else{
					this.setState({modelData: response?.data?.data, devModel: null})
				}



			}
		)
	};
	// 不清空表单
	openType1 = (e,_) => {
		this.setState({classId:e});
		listDeviceModelByDeviceClassId({deviceClassId: e}).then(
			response => {
				console.log('不清空表单');
				console.log(response.data.data);

				this.setState({modelData: response.data.data})
			}
		)
	};
    render() {

		let {modelData, typeData, deviceType,deviceItem} = this.state

        return (
            <Layout className={'item_body_number_back'}>
				<div className={'div_back_arrow'}>
					<span className={'back_arrow'} onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
					<span className={'font_size2'}>表具操作</span>
				</div>
                <div className={'item_body_number_back div_title_from'} style={{marginBottom: 0, marginTop: 0}}>
					<Divider style={{marginTop: 0}}>表具信息</Divider>
                    <FormsTwo
						ref = {e => this.meterFrom = e}
						formParamenter = {this.state.formParamenter}
						data = {this.props.location.state}
					/>
					<FormsTwo
						ref={e => this.deviceFromItem = e}
						formParamenter={this.state.deviceFormItem}
						data={this.state.deviceItem}
					/>
					<Divider>设备信息</Divider>
						<Row gutter={24} style={{marginBottom: '1%'}}>
							<Col span={10} offset={2}>
								<FormItem
                                    label={"设备类型"}
									rules={TYPE_NAME.REQ_SELECT}
                                >
                                    <Select
										disabled={this.props.location.state ? true : false}
										value={this.state.classId}
										defaultValue={deviceItem?.className}
										style={{width: '100%'}}
										placeholder={`选择设备类型`}
										onChange={(e,option) => this.openType(e,option)}
									>
										{
											typeData?.map(({id,name}) => <Option key={id}>{name}</Option>)
										}
									</Select>
                                </FormItem>
							</Col>
							<Col span={10}>
								<FormItem
                                    label={"设备型号"}
									rules={TYPE_NAME.REQ_SELECT}
                                >
                                    <TreeSelect
										// disabled={this.props.location.state ? true : false}
										style={{width: '100%'}}
										value={this.state.devModel}
										defaultValue={deviceType.model ?? null}
										placeholder={`选择设备型号`}
										onChange={(e,option) => this.openModal(e,option)}

										showSearch
										allowClear
										fieldNames={{label: 'devModelName', value: 'devModelId', children: 'children'}}
										treeNodeFilterProp={'devModelName'}
										treeData={modelData}
									>
										{/*{*/}
										{/*	modelData?.map(({devModelId,devModelName}) => <Option key={devModelId}>{devModelName}</Option>)*/}
										{/*}*/}
									</TreeSelect>
                                </FormItem>
							</Col>
						</Row>

					<FormsTwo
						ref={e => this.deviceFrom = e}
						formParamenter={this.state.deviceForm}
						data={this.state.deviceFormItemData}
						getFormValue={this.getDeviceValue}
					/>
                    <div className={'div_button_center'}>
                        <Button type="primary" onClick={this.onSave}>保存</Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button type="primary" onClick={this.clear}>清空</Button>
                    </div>
                </div>
            </Layout>
        )
    }
}
